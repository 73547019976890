import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import ContractForm from '../components/Contracts/ContractForm';
import ContractsList from '../components/Contracts/ContractsList';

class ScreensContracts extends Component {

    render() {
        return (
            <div>
                <Layout>
                    <ContractsList/>
                    <ContractForm/>
                </Layout>
            </div>
        );
    }
}

export default ScreensContracts;