import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScreensLogin from './screens/Login';
import ScreensForgot from './screens/Forgot';
import ScreensLogout from './screens/Logout';
import ScreeesNoMatch from './screens/NoMatch';
import ScreensUsers from './screens/Users';
import ScreensInvited from './screens/Invited';
import ScreensContracts from './screens/Contracts';
import ScreensDashboard from './screens/Dashboard';

const ScreensRoot = () => (
  <Router>
    <Switch>
      {/* Rotas públicas */}
      <Route path="/login" component={ScreensLogin} exact/>
      <Route path="/esqueci-a-senha" component={ScreensForgot} exact/>
      <Route path="/esqueci-a-senha/:token" component={ScreensForgot} strict/>
      <Route path="/convidado/:invitation" component={ScreensInvited} strict/>

      {/* Routas que necessitam de autenticação */}
      <Route path="/usuarios" component={ScreensUsers} strict/>
      <Route path="/propostas" component={ScreensContracts} strict/>
      <Route path="/sair" component={ScreensLogout} />
      <Route path="/" component={ScreensDashboard} exact/>

      {/* Rota 404 */}
      <Route component={ScreeesNoMatch}/>
    </Switch>
  </Router>
);

export default ScreensRoot;