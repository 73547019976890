import React, { Component } from 'react';
import { Form, Col, Row, Button, Icon, Tooltip, Checkbox, Input } from 'antd';
import Image from '../Form/Utils/Image';

const { TextArea } = Input;

class Docs extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            message: null,
            selected: [],
        };
    }

    onChange = (k) => {
        const { selected } = this.state;

        this.setState({selected: selected.includes(k)? selected.filter(r=>r!=k) : [...selected, k]})
    }

    refusal = () => {
        this.props.refusalDoc(this.props.data.id, {
            recused: this.state.selected,
            message: this.state.message
        });
    }

    approve = () => {
        this.props.approveDoc(this.props.data.id);
    }

  render() {
    const { selected } = this.state;
    const { data } = this.props;

    const attachments = data.attachments.filter(row=> (row.file_type === 'Selfie' || row.file_type === 'Doc Frente' || row.file_type === 'Doc Verso' || row.file_type === 'Comprovante Endereço' || row.file_type === 'Cartão'));

    const selfie = attachments.filter(row=> row.file_type ==='Selfie');
    const doc_front = attachments.filter(row=> row.file_type ==='Doc Frente');
    const doc_back = attachments.filter(row=> row.file_type ==='Doc Verso');
    const address = attachments.filter(row=> row.file_type ==='Comprovante Endereço');
    const card = attachments.filter(row=> row.file_type ==='Cartão');

    const editAvaible = (attachments.length >= 4 && data.status === 'Aprovação Pendente');

    return (
      <div>
      <Row gutter={16}>
        <Col span={24}>
            <Image 
                name={'Selfie'}
                changeStatus={editAvaible}
                approved={(selected.includes('Selfie') !== true)}
                onClick={this.onChange}
                attachment={selfie.length ? selfie[0]:null}
                imageUrl={selfie.length ? `${process.env.REACT_APP_API}/storage/docs/${selfie[0].file}` : null}
                {...this.props}
            />
            <Image 
                name={'Doc Frente'}
                changeStatus={editAvaible}
                approved={(selected.includes('Doc Frente') !== true)}
                onClick={this.onChange}
                attachment={doc_front.length ? doc_front[0]:null}
                imageUrl={doc_front.length ? `${process.env.REACT_APP_API}/storage/docs/${doc_front[0].file}` : null}
                {...this.props}
            />
            <Image 
                name={'Doc Verso'}
                changeStatus={editAvaible}
                approved={(selected.includes('Doc Verso') !== true)}
                onClick={this.onChange}
                attachment={doc_back.length ? doc_back[0]:null}
                imageUrl={doc_back.length ? `${process.env.REACT_APP_API}/storage/docs/${doc_back[0].file}` : null}
                {...this.props}
            />
            <Image 
                name={'Comprovante Endereço'}
                changeStatus={editAvaible}
                approved={(selected.includes('Comprovante Endereço') !== true)}
                onClick={this.onChange}
                attachment={address.length ? address[0]:null}
                imageUrl={address.length ? `${process.env.REACT_APP_API}/storage/docs/${address[0].file}` : null}
                {...this.props}
            />
             <Image 
                name={'Cartão'}
                changeStatus={editAvaible}
                approved={(selected.includes('Cartão') !== true)}
                onClick={this.onChange}
                attachment={card.length ? card[0]:null}
                imageUrl={card.length ? `${process.env.REACT_APP_API}/storage/docs/${card[0].file}` : null}
                {...this.props}
            />
        </Col>
       {editAvaible && (
        <Col span={24} className="mb-40">
            {selected.length >= 1 && (
            <Form.Item label={
                <Tooltip title="Descreva os motivos da recusa dos anexos ao cliente.">
                    Mensagem <small>(Opcional)</small> <Icon type="info-circle" />
                </Tooltip>
            }>
                <TextArea rows={3} onChange={(e) => this.setState({message: e.target.value})}/>
            </Form.Item>
            )}
        </Col>
        )}
      </Row>
        {editAvaible && (
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }}
            >
            <Button style={{marginRight: 8 }} onClick={this.props.onClose}>
                Fechar
            </Button>
            {selected.length >= 1?
            <Button 
                type="danger" 
                onClick={this.refusal}>
                Enviar recusa
            </Button>
            :
            <Button 
                className="btn-success"
                onClick={this.approve}>
                Aprovar
            </Button>
            }
        </div>
      )}
    </div>
    )
  }
}

export default Docs;