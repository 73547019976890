import React, { Component } from 'react';
import { Icon, Modal, Tooltip, Button, Descriptions } from 'antd';
import styled from 'styled-components';

const Hover = styled.div`
  width: 80%;
`

const Card = styled.div`
  border: ${(props) => props.changeStatus ? (props.approved? 'solid 3px #52c41a':'solid 3px #f5222d') : 'solid 1px #ccc'} !important;
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border-radius: 4px;
  padding: 0px !important;

  .status{
    position: absolute;
    top: 0px;
    left 0px;
    width: 30px;
    height: 30px;
    background-color ${(props) => props.approved? '#52c41a':'#f5222d'};
    z-index: 10;
    display: none;
    cursor: pointer;
  }

  :hover .status{
    display:block;
  }
  :hover .ant-upload-list-item-actions{
    opacity: 1 !important;
  }
`

class Image extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
      this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
      });
  }

  render() {
    const { name, imageUrl, approved, changeStatus, attachment } = this.props;
    const { previewImage, previewVisible } = this.state;

    return (
      <div>
        {imageUrl !== null ?
        <div className="ant-upload-list ant-upload-list-picture-card">
          <Tooltip placement="bottom" title={name}>
            <Card className="ant-upload-list-item ant-upload-list-item-done" changeStatus={changeStatus} approved={approved}>
                <div className="ant-upload-list-item-info">
                    <span>
                        <a className="ant-upload-list-item-thumbnail" href={imageUrl} target="_blank" rel="noopener noreferrer">
                            <img src={imageUrl} alt={name}/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" className="ant-upload-list-item-name" title={name} href={imageUrl}>{name}</a>
                    </span>
                </div>
                {changeStatus && (
                <div onClick={() => this.props.onClick(name)} className="status">
                  <Icon type={(approved ? 'check':'stop')} style={{color: '#ffffff', fontSize: '18px', margin: '4px'}} />
                </div>
                )}
                <Hover className="ant-upload-list-item-actions">
                  <Button type="default" size="small" onClick={() => this.setState({previewVisible: true, previewImage: imageUrl})} block> Visualizar</Button>
                </Hover>
            </Card>
          </Tooltip>
        </div>
        :
        <div class="ant-upload ant-upload-select ant-upload-select-picture-card ant-upload-disabled" style={{
          float: 'left',
          display: 'table',
          width: '99px',
          }}>
          <span class="ant-upload ant-upload-disabled" role="button">
            <Icon type="clock-circle" />
            <div class="ant-upload-text">{name}</div>
          </span>
        </div>
        }
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="preview" style={{ width: '100%' }} src={previewImage} />
          {attachment !== null && (
          <Tooltip placement="top" title={attachment.ip !== null? <span>
            IP: {attachment.ip}<br />
            Cidade: {attachment.ip_city}<br />
            Estate: {attachment.ip_state}<br />
            País: {attachment.ip_country}
          </span>:
          <span>
            As informações de GeopIP não estão disponivéis para este anexo.
          </span>
          }>
          <a href="javascript:void(0)" className="mt-10"><Icon type="info-circle" /> Informações de GeopIP</a>
          </Tooltip>
          )}
        </Modal>
    </div>
    )
    
  }
}

export default Image;