import React, { Component } from 'react';
import { Form, Col, Row, Input, Tooltip, Icon, Button, Select } from 'antd';

import InputMask from '../../UI/InputMask';

const Option = Select.Option;

class Default extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: {},
            default: {
                protocol: '',
                name: '',
                cpf: '',
                cellphone: '',
                email: '',
                source: null
            },
            activeForm: {}
        };
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.error != this.props.error){
            const { error } = nextProps;

            if(error.response && error.response.status == 422 && error.response.data.email !== null){
                this.setState({
                    email: {
                        validateStatus: 'error',
                        help: error.response.data.email[0]
                    }
                });
            }
        }
    }

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerContract(values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
            });
        });
        
    }

    onSave = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.saveContract(this.props.data.id, values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
            });
        });
        
    }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { email } = this.state;
    const data = {
        ...this.state.default,
        ...this.props.data,
        ...this.state.activeForm
    }

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                {data.id===null && (
                <Col span={4}>
                    <Form.Item label={
                        <Tooltip title="Deixe vázio para gerar automaticamente.">
                            Protocolo <Icon type="info-circle" />
                        </Tooltip>
                    }>
                        {getFieldDecorator('protocol', {initialValue: data.protocol})(
                            <Input placeholder="Protocolo de identificação" />
                        )}
                    </Form.Item>
                </Col>
                )}
                <Col span={(data.id===null)?20:24}>
                    <Form.Item label="Nome do Cliente">
                    {getFieldDecorator('name', {
                        initialValue: data.customer.name,
                        rules: [{ required: true, message: 'Por favor informe o nome do cliente!' }],
                    })(<Input placeholder="Insira o nome do cliente" />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CPF">
                        {getFieldDecorator('cpf', {initialValue: data.customer.cpf})(
                            <InputMask mask="999.999.999-99" placeholder="CPF" ref="cpf" />
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Email" {...email}>
                        {getFieldDecorator('email', {initialValue: data.customer.email})(
                            <Input placeholder="Email do cliente" />
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Celular">
                        {getFieldDecorator('cellphone', {initialValue: data.customer.cellphone})(
                            <InputMask 
                                mask="(99) 9999tt999?" 
                                formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                                maskChar={null} 
                                placeholder="Número de celular" 
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Mídia">
                        {getFieldDecorator('source', {
                            initialValue: data.source,
                            rules: [{ required: true, message: 'Por favor informe a origem do lead!' }],
                        })(
                            <Select>
                                <Option value="Loja">Loja</Option>
                                <Option value="Site">Site</Option>
                                <Option value="Email">Email</Option>
                                <Option value="Facebook">Facebook</Option>
                                <Option value="Instagram">Instagram</Option>
                                <Option value="Whatsapp">Whatsapp</Option>
                                <Option value="SMS">SMS</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e8e8e8',
                    padding: '10px 16px',
                    textAlign: 'right',
                    left: 0,
                    background: '#fff',
                    borderRadius: '0 0 4px 4px',
                }}
            >
                <Button style={{marginRight: 8 }} onClick={this.props.onClose}>
                    Fechar
                </Button>
                {data.id !==null?
                <Button 
                    type="primary"
                    onClick={this.onSave}>
                    Salvar
                </Button>
                :
                <Button 
                    type="primary" 
                    onClick={this.onCreate}>
                    Cadastrar
                </Button>
                }
            </div>
      </Form>
    )
  }
}

export default Form.create()(Default);