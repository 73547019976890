import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Instance from '../../Instance';

class Auth extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: (props.user.id !== undefined)? false:true
        }
    }

    loadUser = () => {
        Instance().get(`/user`)
        .then(response => {
            this.props.setUser(response.data);
            this.props.loadUsers();
            this.setState({loading: false});

        })
        .catch(error => {
            if(error.response.status === 401){
                this.props.history.push('/login');
            }
        })
    }

    componentDidMount = () => {
        if(localStorage.getItem('api_token') === null){
            this.props.history.push('/login');
        }else{
            this.loadUser();
        }
    }

    render() {
        const { loading } = this.state;
        return (
            <div>
                <div className={`pageloader ${(loading)? 'is-active':''}`}></div>
                {/* <div className="infraloader"></div> */}
                {this.props.children}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: data => {dispatch({type: 'SET_DATA_USER', payload: data})},
        loadUsers: (data) => dispatch({
            type: 'LOAD_USERS',
            payload: {
              request:{
                method: 'get',
                url:'/users',
                data: data
              }
            }
        }),
    }
}


const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));