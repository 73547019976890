import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Alert, Icon, Menu } from 'antd';

import TabDefault from './Form/Default';
import TabDocs from './Contract/Docs';
import TabContract from './Contract/Contract';
import SectionCopiesContract from './Contract/Copies';

const SubMenu = Menu.SubMenu;

class ContractForm extends React.Component {
    state = {
        error: null,
        tabActive: '1',
        default: {
            id: null,
            file: null,
            file_self: null,
            file_doc_front: null,
            file_doc_back: null,
            customer: {
                name: null,
                cpf: null,
                email: null,
                cellphone: null,
            }
        }
    }

    render() {
        const { tabActive } = this.state;
        const { contract } = this.props;
        const data = {
            ...this.state.default,
            ...contract
        }

        return (
        <div>
            <Drawer
                title={(data.id)? `Proposta ${data.protocol} (${data.status})`: 'Cadastrar Proposta'}
                width={600}
                placement="right"
                onClose={this.props.onClose}
                maskClosable={false}
                visible={(contract!==null)}
                destroyOnClose={true}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53
                }}
                bodyStyle={{
                  // paddingLeft: 8,
                  // paddingRight: 8,
                  paddingTop: 10
                }}
            >
            {contract && (
            <div>

                {contract.status == 'Aprovação Pendente' && tabActive !== '2' && (
                <Alert
                    message="Aprovação Pendente"
                    description={<span>O cliente já fez o envio dos documentos pessoais. Verifique se todos os anexos estão corretos e aprove ou faça a recusa. <Button type="primary" size="small" onClick={() => this.setState({tabActive: '2'})}>Ok</Button></span>}
                    type="info"
                    className="mb-10"
                    showIcon
                />
                )}
                {contract.status == 'Contrato Pendente' && tabActive !== '3' && (
                <Alert
                    message="Contrato Pendente"
                    description={<span>Anexe o contrato. Em seguida será solicitado o aceite eletrônico ao cliente e envio das fotos do contrato assinado. <Button type="primary" size="small" onClick={() => this.setState({tabActive: '3'})}>Ok</Button></span>}
                    type="info"
                    className="mb-10"
                    showIcon
                />
                )}

                {contract.status == 'Aguardando Aprovação' && tabActive !== '4' && (
                <Alert
                  message="Aguardando Aprovação"
                  description={<span>O cliente já fez o envio das cópias do contrato. Verifique se todos os anexos estão corretos e aprove ou faça a recusa. <Button type="primary" size="small" onClick={() => this.setState({tabActive: '4'})}>Ok</Button></span>}
                  type="info"
                  className="mb-10"
                  showIcon
                />
                )}

            {data.id && (
            <Menu
                onClick={(e) => this.setState({tabActive: e.key})}
                selectedKeys={[tabActive]}
                mode="horizontal"
                className="mb-10"
              >
                <Menu.Item key="1">
                  <Icon type="profile" /> Cliente
                </Menu.Item>
                <Menu.Item key="2">
                  <Icon type="file" /> Documentos
                </Menu.Item>
                <SubMenu title={<span className="submenu-title-wrapper"><Icon type="file-pdf" /> Contrato</span>}>
                  <Menu.Item key="3"><Icon type="upload" /> Anexo do contrato</Menu.Item>
                  <Menu.Item key="4"><Icon type="audit" /> Cópias do contrato</Menu.Item>
                </SubMenu>
              </Menu>
            )}

              {tabActive === '1' && (<TabDefault {...this.props} data={data}/>)}
              {tabActive === '2' && (<TabDocs {...this.props} data={data}/>)}
              {tabActive === '3' && (<TabContract {...this.props} data={data}/>)}
              {tabActive === '4' && (<SectionCopiesContract {...this.props} data={data}/>)}
            </div>
            )}
          </Drawer>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        contract: state.contracts.opened
    }
}

const mapDispatchProps = dispatch => {
    return{
      registerContract: (data) => dispatch({
        type: 'REGISTER_CONTRACT',
        payload: {
          request:{
            method: 'post',
            url:'/contracts',
            data: data
          }
        }
      }),
      saveContract: (id, data) => dispatch({
        type: 'SAVE_CONTRACT',
        payload: {
          request:{
            method: 'post',
            url:`/contracts/${id}`,
            data: data
          }
        }
      }),
      refusalDoc: (id, data) => dispatch({
        type: 'SAVE_CONTRACT',
        payload: {
          request:{
            method: 'put',
            url:`/contracts/${id}/doc/refusal`,
            data: data
          }
        }
      }),
      approveDoc: (id, data) => dispatch({
        type: 'SAVE_CONTRACT',
        payload: {
          request:{
            method: 'put',
            url:`/contracts/${id}/doc/approve`,
          }
        }
      }),
      onClose: () => dispatch({
        type: 'OPEN_CONTRACT',
        payload: null
      }),
      setContract: (data, update = true) => dispatch({
        type: 'UPDATE_CONTRACT',
        payload: data,
        updateOpenContract: update
      })
    }
  }
  
export default connect(mapStateToProps, mapDispatchProps)(ContractForm);