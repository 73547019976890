import React, { Component } from 'react';
import { Form, Col, Row, Button, Icon, Tooltip, Checkbox, Input } from 'antd';
import Image from '../Form/Utils/Image';
import { request } from '../../Instance';

const { TextArea } = Input;

class CopiesContract extends Component {

  constructor(props){
    super(props);

    this.state = {
      loading: false,
      message: null,
      selected: [],
    };
  }

  onChange = (k) => {
    const { selected } = this.state;

    this.setState({selected: selected.includes(k)? selected.filter(r=>r!=k) : [...selected, k]})
  }

  refusal = () => {
    const { selected, message } = this.state;

    request('PUT', `/contracts/${this.props.data.id}/contract/refusal`, {
      selected,
      message
    })
    .then(response => {
      this.props.setContract(response.data);
    })
  }

  approve = () => {
    request('PUT', `/contracts/${this.props.data.id}/contract/approve`)
    .then(response => {
      this.props.setContract(response.data);
    })
  }

  render() {
    const { selected } = this.state;
    const { data } = this.props;

    const attachments = data.attachments.filter(row => row.file_type.indexOf('Página') >= 0);

    const createImageList = () => {

      let image = [];
      for(let i=1; i<=data.pages; i++){
        let attachment = attachments.filter(row => row.file_type===`Página ${i}`);

        image.push(<Image 
            name={`Página ${i}`}
            changeStatus={(attachments.length === data.pages && data.status === 'Aguardando Aprovação')}
            approved={(selected.includes(`Página ${i}`) !== true)}
            onClick={this.onChange}
            attachment={attachment.length ? attachment[0] : null}
            imageUrl={attachment.length ? `${process.env.REACT_APP_API}/storage/signatures/${attachment[0].file}` : null}
            {...this.props}
            />
        )
      }

      return image;
    }

    return (
      <div>
      <Row gutter={16}>
        {data.file && (
          <Col span={24}>
            {createImageList()}
          </Col>
        )}
       {attachments.length === data.pages && data.status === 'Aguardando Aprovação' && (
        <Col span={24} className="mb-40">
            {selected.length >= 1 && (
            <Form.Item label={
                <Tooltip title="Descreva os motivos da recusa dos anexos ao cliente.">
                    Mensagem <small>(Opcional)</small> <Icon type="info-circle" />
                </Tooltip>
            }>
                <TextArea rows={3} onChange={(e) => this.setState({message: e.target.value})}/>
            </Form.Item>
            )}
        </Col>
        )}
      </Row>
        {attachments.length === data.pages && data.status === 'Aguardando Aprovação' && (
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }}
            >
            <Button style={{marginRight: 8 }} onClick={this.props.onClose}>
                Fechar
            </Button>
            {selected.length >= 1?
            <Button 
                type="danger" 
                onClick={this.refusal}>
                Enviar recusa
            </Button>
            :
            <Button 
                className="btn-success"
                onClick={this.approve}>
                Aprovar
            </Button>
            }
        </div>
      )}
    </div>
    )
  }
}

export default CopiesContract;