import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Button } from 'antd';
import { withRouter } from 'react-router';
import UsersModalForm from './UsersModalForm';

const { Column } = Table;

const getColorByStatus = (status) => {
  switch(status){
    case 'Ativo': return 'green';
    case 'Convidado': return 'purple';
    case 'Desativado': return 'red';
    default: return 'blue';
  }
}

class UsersList extends Component {

    state = {
        openModal: false
    }

  componentDidMount = () => {
    if(this.props.user.perfil!=='Administrador'){
      this.props.history.push('/');
    }

    this.props.loadUsers();
  }

  onAction = (e, user) => {
    switch(e.key){
      case '0': 
      this.props.showEditPermissions(user);
      break;
      case '1': 
        Modal.confirm({
          title: 'Você realmente deseja desabilitar este usuário?',
          content: 'Você poderá reverter esta ação no futuro.',
          okText: 'Sim',
          okType: 'warning',
          cancelText: 'Cancelar',
          onOk: () => this.props.disableUser(user.id)
        });
      break;
      case '1.change': 
        this.props.activeUser(user.id)
      break;
      case '3': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este usuário?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteUser(user.id)
        });
      break;
    }
  }

  render() {
    const { user, users } = this.props;

    const showName = (name) => name !== ''? name:'Convidado';

    return (  
    <div>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
          <h1 style={{fontSize: `20px`}}>Usuários {' '}
            <Button 
                icon="user-add" 
                type="primary" 
                style={{float: `right`}} 
                onClick={() => this.setState({openModal: true})}
                >
                Novo
            </Button>
          </h1>
        </div>
        <UsersModalForm 
            visible={this.state.openModal} 
            onCancel={() => this.setState({openModal: false})}
            {...this.props}
        />
        <div className="table-clabs">
            <Table 
            rowKey="key"
            dataSource={users} 
            pagination={{ pageSize: 10 }}
            bordered={true}
            rowClassName="row-clabs"
            >
            <Column
                title="Nome"
                dataIndex="name"
                key="name"
                render={(text, record, index) => (
                <span>
                    {(record.id == user.id)? <b>{showName(record.name)}</b> : showName(record.name)} <Tag color="blue">{record.perfil}</Tag>
                </span>
                )}
            />
            <Column
                title="Email"
                dataIndex="email"
                key="email"
            />
            <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={status => (
                    <center>
                    <Tag color={getColorByStatus(status)} key={status}>{status}</Tag>
                    </center>
                )}
            />
            {(users.length > 1) && (
            <Column
                dataIndex="actions"
                key="actions"
                render={(text, record, index) => (
                (record.id != user.id) && (
                <center key={index}>
                    <Dropdown overlay={
                        <Menu onClick={(e) => this.onAction(e, record)}>
                        {/* <Menu.Item key="0">
                            <Icon type="form"></Icon> Permissões
                        </Menu.Item> */}
                        {/* {(record.status != 'Desativado')?
                        <Menu.Item key="1">
                            <Icon type="stop"></Icon> Desabilitar
                        </Menu.Item>
                        :
                        <Menu.Item key="1.change">
                            <Icon type="check"></Icon> Ativar
                        </Menu.Item>
                        } */}
                        {/* <Menu.Divider /> */}
                        <Menu.Item key="3"><Icon type="delete"></Icon> Excluir</Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" href="#">
                        Ações <Icon type="down" />
                        </a>
                    </Dropdown>
                </center>
                )
                )}
            />
            )}
            </Table>
        </div>
    </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    user: state.user,
    users: state.users
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadUsers: (data) => dispatch({
      type: 'LOAD_USERS',
      payload: {
        request:{
          method: 'get',
          url:'/users',
          data: data
        }
      }
    }),
    disableUser: (id) => dispatch({
      type: 'DISABLE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/disable',
        }
      },
      id: id
    }),
    activeUser: (id) => dispatch({
      type: 'ACTIVE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/active',
        }
      },
      id: id
    }),
    deleteUser: (id) => dispatch({
      type: 'DELETE_USER',
      payload: {
        request:{
          method: 'delete',
          url:'/user/'+id,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(withRouter(UsersList));