import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Dropdown, Menu, Modal, Button, Input, Tag, Badge } from 'antd';
import { compare } from 'natural-orderby';
import Moment from 'react-moment';

const { Column } = Table;
const Search = Input.Search;

const getColorTag = (status) => {
  switch(status){
      case 'Documentação Pendente': return '#fadb14';
      case 'Documentação Recusada': return '#faad14';
      case 'Aprovação Pendente': return '#eb2f96';
      case 'Contrato Pendente': return '#722ed1';
      case 'Aceite Pendente': return '#2f54eb';
      case 'Assinatura Pendente': return '#1890ff';
      case 'Aguardando Aprovação': return '#13c2c2';
      case 'Cópia Recusada': return '#faad14';
      case 'Aprovado': return '#52c41a';
      case 'Cancelado': return '#f5222d';
  } 
}

class ContractsList extends Component {
  state = {
    search: ''
  }

  componentDidMount = () => this.props.loadContracts();

  onAction = (e, contract) => {
    switch(e.key){
      case 'edit': 
      this.props.openContract(contract);
      break;
      case 'cancel': 
      Modal.confirm({
        title: 'Você realmente deseja cancelar esta proposta?',
        content: 'Você poderá desfazer essa ação no futuro',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => this.props.cancelContract(contract.id)
      });
    break;
    case 'uncancel': 
    Modal.confirm({
      title: 'Você realmente deseja restaurar esta proposta?',
      content: 'Você poderá desfazer essa ação no futuro',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => this.props.unCancelContract(contract.id)
    });
  break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir esta proposta?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteContract(contract.id)
        });
      break;
    }
  }

  render() {
    const { contracts } = this.props;
    const { search } = this.state;

    let filtered = (contracts.data.length)? contracts.data.filter((str) => {

      if(str.customer.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.protocol.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

    return (  
      <div>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
          <h1 style={{fontSize: `20px`}}>Propostas {' '}
            <Search 
              placeholder="Pesquisar por clientes ou protocolos" 
              onChange={e => this.setState({search: e.target.value})}
              style={{width: `30%`}}
            />
            <Button 
                icon="file-add" 
                type="primary" 
                style={{float: `right`}} 
                onClick={() => this.props.openContract({})}
                >
                Cadastrar
            </Button>
          </h1>
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            loading={(!contracts.loaded)}
            dataSource={filtered} 
            pagination={{ 
              showSizeChanger: true, 
              showTotal: (total) =>  `Total de ${total} propostas`,
              pageSizeOptions: ['10', '20', '40', '100', '500', '1000']
            }}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: 2220, y: `${window.innerHeight-175}px`}}
          >
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              fixed="left"
              width={200}
              sorter={(a, b) => a.status && compare()(a.status, b.status)}
              filters={[{
                text: <Badge color={getColorTag('Documentação Pendente')} text={`Documentação Pendente`} />,
                value: 'Documentação Pendente',
              },{
                text: <Badge color={getColorTag('Documentação Recusada')} text="Documentação Recusada" />,
                value: 'Documentação Recusada',
              }, {
                text: <Badge color={getColorTag('Aprovação Pendente')} text="Aprovação Pendente" />,
                value: 'Aprovação Pendente',
              },{
                text: <Badge color={getColorTag('Contrato Pendente')} text="Contrato Pendente" />,
                value: 'Contrato Pendente',
              },
              {
                text: <Badge color={getColorTag('Aceite Pendente')} text="Aceite Pendente" />,
                value: 'Aceite Pendente',
              },
              {
                text: <Badge color={getColorTag('Assinatura Pendente')} text="Assinatura Pendente" />,
                value: 'Assinatura Pendente',
              },
              {
                text: <Badge color={getColorTag('Aguardando Aprovação')} text="Aguardando Aprovação" />,
                value: 'Aguardando Aprovação',
              },
              {
                text: <Badge color={getColorTag('Cópia Recusada')} text="Cópia Recusada" />,
                value: 'Cópia Recusada',
              },
              {
                text: <Badge color={getColorTag('Aprovado')} text="Aprovado" />,
                value: 'Aprovado',
              },
              {
                text: <Badge color={getColorTag('Cancelado')} text="Cancelado" />,
                value: 'Cancelado',
              },
            ]}
            filterMultiple={false}
            onFilter={(value, record) => record.status.indexOf(value) >= 0}
            render={status => <center><Tag color={getColorTag(status)} key={status}>{status}</Tag></center>}
            />
            <Column
                title="Cliente"
                dataIndex="customer.name"
                key="customer.name"
                fixed="left"
                width={300}
                sorter={(a, b) => a.customer.name && a.customer.name.localeCompare(b.customer.name)}
            />
            <Column
                title="Protocolo"
                dataIndex="protocol"
                key="protocol"
                width={150}
                sorter={(a, b) => a.protocol && compare()(a.protocol, b.protocol)}
            />
            <Column
                title="Data"
                dataIndex="created_at"
                key="created_at"
                width={150}
                sorter={(a, b) => a.created_at && compare()(a.created_at, b.created_at)}
                sortDirections={['descend', 'ascend']}
                defaultSortOrder={'descend'}
                render={key => key && (
                  <Moment format="DD/MM/YYYY">
                    {key}
                  </Moment>
              )}
            />
            <Column
                title="Link"
                dataIndex="protocol"
                key="link"
                width={350}
                render={key => <a href={`${process.env.REACT_APP_DOC}/${key}`} target="_blank">{`${process.env.REACT_APP_DOC}/${key}`}</a>}
            />
            <Column
                title="Email"
                dataIndex="customer.email"
                key="customer.email"
                width={320}
                sorter={(a, b) => a.customer.email && a.customer.email.localeCompare(b.customer.email)}
            />
            <Column
                title="CPF"
                dataIndex="customer.cpf"
                key="customer.cpf"
                width={200}
            />
            <Column
                title="Telefone"
                dataIndex="customer.cellphone"
                key="customer.cellphone"
                width={200}
            />
            <Column
                title="Mídia"
                dataIndex="source"
                key="source"
                width={200}
                filters={[{
                  text: 'Loja',
                  value: 'Loja',
                },{
                  text: 'Site',
                  value: 'Site',
                }, {
                  text: 'Email',
                  value: 'Email',
                },{
                  text: 'Facebook',
                  value: 'Facebook',
                },
                {
                  text: 'Instagram',
                  value: 'Instagram',
                },
                {
                  text: 'Whatsapp',
                  value: 'Whatsapp',
                }
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.source.indexOf(value) >= 0}
            />
            <Column
                dataIndex="actions"
                key="actions"
                fixed="right"
                width={150}
                render={(text, record, index) => (
                <center key={index}>
                    <Dropdown overlay={
                        <Menu onClick={(e) => this.onAction(e, record)}>
                        <Menu.Item key="edit">
                            <Icon type="eye"></Icon> Visualizar
                        </Menu.Item>
                        <Menu.Divider />
                        {record.status !== 'Cancelado'?
                        <Menu.Item key="cancel"><Icon type="close"></Icon> Cancelar</Menu.Item>
                        :
                        <Menu.Item key="uncancel"><Icon type="check"></Icon> Restaurar</Menu.Item>
                        }
                        <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" href="#">
                        Ações <Icon type="down" />
                        </a>
                    </Dropdown>
                </center>
            )}
            />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  const contracts = (state.user.perfil === 'Atendente')? {...state.contracts, data: state.contracts.data.filter(r=>r.created_by===state.user.id)}: state.contracts;
  return{
    contracts
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadContracts: () => dispatch({
      type: 'LOAD_CONTRACTS',
      payload: {
        request:{
          method: 'get',
          url:'/contracts',
          showLoading: false
        }
      }
    }),
    deleteContract: (id) => dispatch({
      type: 'DELETE_CONTRACT',
      payload: {
        request:{
          method: 'delete',
          url:'/contracts/'+id,
          showLoading: false
        }
      },
      id: id
    }),
    cancelContract: (id) => dispatch({
      type: 'CANCEL_CONTRACT',
      payload: {
        request:{
          method: 'put',
          url:'/contracts/'+id+'/cancel',
          showLoading: false
        }
      },
      id: id
    }),
    unCancelContract: (id) => dispatch({
      type: 'CANCEL_CONTRACT',
      payload: {
        request:{
          method: 'put',
          url:'/contracts/'+id+'/uncancel',
          showLoading: false
        }
      },
      id: id
    }),
    openContract: (data) => dispatch({
      type: 'OPEN_CONTRACT',
      payload: data
    }),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(ContractsList);