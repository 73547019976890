import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScreensLogout extends Component {

    componentDidMount = () => {
        localStorage.removeItem('api_token');
        this.props.history.push('/login');
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default withRouter(ScreensLogout);