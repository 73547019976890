import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Statistic } from 'antd';
import Chart from 'react-apexcharts'; 

const getColorTag = (status) => {
  switch(status){
      case 'Documentação Pendente': return '#fadb14';
      case 'Documentação Recusada': return '#faad14';
      case 'Aprovação Pendente': return '#eb2f96';
      case 'Contrato Pendente': return '#722ed1';
      case 'Aceite Pendente': return '#2f54eb';
      case 'Assinatura Pendente': return '#1890ff';
      case 'Aguardando Aprovação': return '#13c2c2';
      case 'Cópia Recusada': return '#faad14';
      case 'Aprovado': return '#52c41a';
      case 'Cancelado': return '#f5222d';
  } 
}

class Index extends Component {

  componentDidMount = () => this.props.loadContracts();

  render() {
    const { contracts } = this.props;

    return (  
      <div>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
          <h1 style={{fontSize: `20px`}}>DASHBOARD {' '}
          </h1>
        </div>
        <Row gutter={16}>
            <Col span={12}>
                <Card loading={(!contracts.loaded)}>
                    <Statistic 
                        title="Propostas em Andamento" 
                        valueStyle={{ color: getColorTag('Documentação Pendente') }}
                        value={contracts.data.filter(r=>(r.status !== 'Aprovado' && r.status !== 'Cancelado')).length} />
                </Card>
            </Col>
            <Col span={12}>
                <Card loading={(!contracts.loaded)}>
                    <Statistic 
                        title="Propostas Aprovadas" 
                        valueStyle={{ color: getColorTag('Aprovado') }}
                        value={contracts.data.filter(r=>r.status === 'Aprovado').length} />
                </Card>
            </Col>
        </Row>
        {/* <Row gutter={16} className="mt-15">
            <Col span={12}>
                <Card loading={(!contracts.loaded)}>
                    <div id="chart">
                        <Chart 
                            options={{
                                colors: [getColorTag('Documentação Pendente'), getColorTag('Documentação Recusada'), getColorTag('Aprovação Pendente'), getColorTag('Contrato Pendente'), getColorTag('Aceite Pendente'), getColorTag('Assinatura Pendente'), getColorTag('Aguardando Aprovação'), getColorTag('Cópia Recusada'), getColorTag('Aprovado'), getColorTag('Cancelado')],
                                labels: ['Documentação Pendente', 'Documentação Recusada', 'Aprovação Pendente', 'Contrato Pendente', 'Aceite Pendente', 'Assinatura Pendente', 'Aguardando Aprovação', 'Cópia Recusada', 'Aprovado', 'Cancelado'],
                                chart: {
                                    width: 200,
                                    height: 200
                                },
                                responsive: [{
                                  breakpoint: 480,
                                  options: {
                                    chart: {
                                      width: 300
                                    },
                                    legend: {
                                      position: 'bottom'
                                    }
                                  }
                                }],
                              }} 
                            series={[
                                contracts.data.filter(r=>r.status === 'Documentação Pendente').length, 
                                contracts.data.filter(r=>r.status === 'Documentação Recusada').length, 
                                contracts.data.filter(r=>r.status === 'Aprovação Pendente').length, 
                                contracts.data.filter(r=>r.status === 'Contrato Pendente').length, 
                                contracts.data.filter(r=>r.status === 'Aceite Pendente').length, 
                                contracts.data.filter(r=>r.status === 'Assinatura Pendente').length, 
                                contracts.data.filter(r=>r.status === 'Aguardando Aprovação').length, 
                                contracts.data.filter(r=>r.status === 'Cópia Recusada').length, 
                                contracts.data.filter(r=>r.status === 'Aprovado').length, 
                                contracts.data.filter(r=>r.status === 'Cancelado').length
                            ]} 
                            type="pie" 
                            width="100%"
                        />
                    </div>
                </Card>
            </Col>
        </Row> */}
      </div>
      )
  }
}

const mapStateToProps = state => {
  const contracts = (state.user.perfil === 'Atendente')? {...state.contracts, data: state.contracts.data.filter(r=>r.created_by===state.user.id)}: state.contracts;
  return{
    contracts
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadContracts: () => dispatch({
      type: 'LOAD_CONTRACTS',
      payload: {
        request:{
          method: 'get',
          url:'/contracts',
          showLoading: false
        }
      }
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Index);