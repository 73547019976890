import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Select } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

class UsersModalForm extends React.Component {
    state = {
        email: {}
    };

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerUser(values)
            .then(response => {
                form.resetFields();
                context.props.onCancel();
            })
            .catch(data => {
                if(data.error.response.status == 422 && data.error.response.data.email !== null){
                    context.setState({
                        email: {
                            validateStatus: 'error',
                            help: data.error.response.data.email[0]
                        }
                    });
                }
            });
        });
        
    }

    render() {
        const { visible, onCancel, form } = this.props;
        const { getFieldDecorator } = form;
        const { email } = this.state;

        return (
            <Modal
                visible={visible}
                title="Convidar novo usuário"
                okText="Convidar"
                onCancel={onCancel}
                onOk={this.onCreate}>
                <Form layout="vertical">
                    <FormItem label="Email" {...email}>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Por favor informe o email!' }],
                    })(
                        <Input onChange={() => this.setState({email:{}})}/>
                    )}
                    </FormItem>
                    <FormItem label="Perfil" className="collection-create-form_last-form-item">
                    {getFieldDecorator('perfil', {
                        rules: [{ required: true, message: 'Selecione um perfil para este usuário!' }],
                    })(
                        <Select>
                            <Option value="Administrador">Administrador</Option>
                            <Option value="Revisor">Revisor</Option>
                            <Option value="Atendente">Atendente</Option>
                        </Select>
                    )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchProps = dispatch => {
    return{
      registerUser: (data) => dispatch({
        type: 'REGISTER_USER',
        payload: {
          request:{
            method: 'post',
            url:'/user',
            data: data
          }
        }
      })
    }
  }
  
  export default connect(null, mapDispatchProps)(Form.create()(UsersModalForm));