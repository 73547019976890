import React, { Component } from 'react';
import { Form, Col, Row, Upload, Button, Icon, Alert, Checkbox, Input } from 'antd';
import { request } from '../../Instance';

class Contract extends Component {

  constructor(props){
    super(props);

    this.state = {
      filename: props.data.file_original?props.data.file_original:'Anexar Contrato',
      file: null,
      loading: false,
    };
  }

  handleUpload = () => {
    const { file } = this.state;
    const formData = new FormData();

    formData.append('file', file);

    this.setState({
      loading: true,
    });

    request('POST', `/contracts/${this.props.data.id}/contract/upload`, formData)
    .then(response => {

      this.setState({
        loading: false,
        file: null
      });

      this.props.setContract(response.data);

    })
    .catch(response => this.setState({loading: false}))

  }

  render() {
    const { filename, file, loading } = this.state;
    const { data } = this.props;

    return (
      <div>
      <Row gutter={16}>
        <Col span={24}>
            <Form.Item label="Contrato">
              <Upload 
                name="file"
                showUploadList={false}
                accept=".pdf"
                beforeUpload={(file) => {
                  this.setState(state => ({
                    file: file,
                    filename: file.name
                  }));
                  return false;
                }}>
                <Button loading={loading}>
                    {!loading &&(<Icon type="upload" />)} {filename}
                </Button>
              </Upload>
            </Form.Item>
            {((data.status === 'Contrato Pendente' && file) || (data.file && file)) && (
              <div>
                <Alert
                  className="mb-10"
                  type="info" 
                  description="Ao clicar em enviar, o cliente será notificado por e-mail."/>
                <Button 
                  onClick={this.handleUpload}
                  type="primary">
                  Enviar
                </Button>
              </div>
            )}
        </Col>
      </Row>
    </div>
    )
  }
}

export default Contract;