const users = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_USERS_SUCCESS':
        return action.payload.data;

        case 'REGISTER_USER_SUCCESS':
        return [
            ...state,
            {...action.payload.data.data}
        ];
        
        case 'DISABLE_USER_SUCCESS':
        return [
            ...state.map(item => (item.id == action.meta.previousAction.id)? {...item, status: 'Desativado'}:item),
        ];

        case 'ACTIVE_USER_SUCCESS':
        return [
            ...state.map(item => (item.id == action.meta.previousAction.id)? {...item, status: 'Ativo'}:item),
        ];

        case 'DELETE_USER_SUCCESS':
        return [
            ...state.filter(item => item.id != action.meta.previousAction.id),
        ];

        case 'SAVE_PERMISSIONS_USER_SUCCESS':
        return [
            ...state.map(item => (item.id == action.meta.previousAction.id)? {...item, permissions: action.meta.previousAction.payload.request.data.permissions}:item),
        ];

        default:
        return state
    }
}

export default users;