import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import UsersList from '../components/Users/UsersList';

class ScreensUsers extends Component {

    render() {
        return (
            <div>
                <Layout>
                    <UsersList/>
                </Layout>
            </div>
        );
    }
}

export default ScreensUsers;