const defaultState = {
    name: null,
    tenant: {
        name: null
    }
}
const user = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_DATA_USER':
        return action.payload;
        break;
        case 'UPDATE_USER':
        return {
            ...state,
            ...action.payload
        }
        break;
        case 'UPDATE_COMPANY':
        return {
            ...state,
            tenant:{
                ...state.tenant,
                ...action.payload
            }
        }
        break;
        case 'CHANGE_PASSWORD':
        return {
            ...state
        }
        break;
        default:
        return state
    }
}

export default user;