import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import Dashboard from '../components/Dashboard/Index';

class ScreensDashboard extends Component {

    render() {
        return (
            <div>
                <Layout>
                    <Dashboard/>
                </Layout>
            </div>
        );
    }
}

export default ScreensDashboard;