import React from 'react';
import { Row, Col } from 'antd';
import Login from '../components/Login/Login.js';
import styled from 'styled-components';

import graphic from '../components/Login/graphic.svg';
import logo from '../components/UI/Layout/images/logo.png';


const Main = styled.main`
    height: 100vh;
    width: 100%;
    background-color: #283895;
`

const Hover = styled(Col)`
    background-color: #fff;
    height: 100vh;
    padding: 75px !important;
`

const Title = styled.h3`
    display: inline-block;
    color: #283895;
    text-align: left;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
    width: 100%;
    max-width: 378px;
    padding-right: 30px;
`

const Form = styled(Login)`
`

const ScreensLogin = () => (
    <Main>
        <Row type="flex" justify="center" align="middle">
            <Hover span={10}>
                <Title>Faça o login para ter acesso a plataforma completa</Title>
                <img src={graphic} alt=""/>
            </Hover>
            <Col span={14}>
                <Row type="flex" justify="center" align="middle">
                    <Col span={12}>
                        <img src={logo} width="70%" alt="Logo" style={{marginBottom:`50px`}}/>
                        <Form />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Main>
);

export default ScreensLogin;