const contracts = (state = {
    loaded: false,
    opened: null,
    data: []
}, action) => {
    switch (action.type) {
        case 'LOAD_CONTRACTS_SUCCESS':
        return {
            loaded: true,
            opened: null,
            data: action.payload.data
        };

        case 'REGISTER_CONTRACT_SUCCESS':
        return {
            loaded: true,
            opened: null,
            data: [
                ...state.data,
                {...action.payload.data.data}
            ]
        };

        case 'DELETE_CONTRACT_SUCCESS':
        return {
            loaded: true,
            opened: null,
            data: [
                ...state.data.filter(item => item.id != action.meta.previousAction.id),
            ]
        };

        case 'SAVE_CONTRACT_SUCCESS':
        return {
            loaded: true,
            opened: action.payload.data.data,
            data: [
                ...state.data.map(item => item.id != action.payload.data.data.id? item : action.payload.data.data),
            ]
        };

        case 'CANCEL_CONTRACT_SUCCESS':
        return {
            loaded: true,
            opened: null,
            data: [
                ...state.data.map(item => item.id != action.payload.data.data.id? item : action.payload.data.data),
            ]
        };

        case 'OPEN_CONTRACT':
        return {
            ...state,
            opened: action.payload
        };

        case 'UPDATE_CONTRACT':
        return {
            ...state,
            opened: action.updateOpenContract? action.payload.data:state.opened,
            data: [
                ...state.data.map(item => item.id != action.payload.data.id? item : action.payload.data),
            ]
        };

        default:
        return state
    }
}

export default contracts;