import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Form, Icon, Input, Button, notification, Row, Col } from 'antd';

import { request } from '../Instance';

class Login extends Component {

    state = {
        loading: false
    }

    loading = (val) => this.setState({loading: val});

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;

        this.props.form.validateFields((err, values) => {
            if (!err) {

                _this.loading(true);

                request('post', `/login`, values, false)
                .then(response => {
                    localStorage.setItem('api_token', response.data.api_token);
                    _this.props.history.push('/');
                })
                .catch(function (error) {
                    if(error.response.data.hasOwnProperty('message')){
                        notification['warning']({
                            message: 'Opss',
                            description: error.response.data.message,
                          });
                    }else{
                        notification['warning']({
                            message: 'Opss',
                            description: 'Email ou senha incorreta!',
                          });
                    }
                    _this.loading(false);
                });
            }
        });
    }
      
    render() {
        const { getFieldDecorator } = this.props.form;
        const { loading } = this.state;

        return (
        <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
            {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Por favor insira seu email' }],
            })(
                <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" type="email" placeholder="Email" />
            )}
            </Form.Item>
            <Form.Item>
            {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Por favor insira sua senha!' }],
            })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" type="password" placeholder="Senha" />
            )}
            </Form.Item>
            <Button 
                type="default" 
                size="large" 
                htmlType="submit" 
                className="login-form-button" 
                loading={loading}
                block>
                Login
            </Button>
            <Row style={{marginTop: '10px'}}>
                <a className="login-form-forgot" style={{color: '#44c6e9'}} href="/esqueci-a-senha">Esqueci a senha</a>
            </Row>
        </Form>
        );
    }
}
export default withRouter(Form.create()(Login));