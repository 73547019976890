import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Form, Icon, Input, Button, notification, Spin } from 'antd';

import { request } from '../Instance';

class InvitedForm extends Component {

    state = {
        loading: false,
        status: 'validating',
        spin: 'Validando convite..',
        error: null,
        email: null
    }

    componentDidMount = () => this.validate(this.props.match.params.invitation);

    validate = (invitation) => {
        const context = this;
        request('post', `${process.env.REACT_APP_API}/invited/validate`,{
            invitation
        }, false)
        .then(response => {
            context.setState({
                status: 'valid', 
                email: response.data.data
            });
        })
        .catch(function (error) {
            context.setState({
                status: 'error', 
                error: 'Convite inválido ou expirado.'
            });
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        const context = this;
        const { invitation } = this.props.match.params;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.setState({
                status: 'submiting', 
                spin: 'Aguarde...'
            });

            request('post', `${process.env.REACT_APP_API}/invited/register`,{
                ...values,
                invitation
            })
            .then(response => {
                localStorage.setItem('api_token', response.data.api_token);
                window.location = '/';
            })
            .catch(function (error) {
                context.setState({
                    status: 'error', 
                    error: 'Não foi possivél concluir sua solicitação. Atualize a página e tente novamente.'
                });
            });

        });
        
    }

    loading = (val) => this.setState({loading: val});

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loading, spin, status, error } = this.state;

        return (
        <Spin tip={spin} size="large" spinning={(status == 'validating' || status == 'submiting')}>
            {status == 'valid' && (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Por favor digite seu nome completo' }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" type="text" placeholder="Nome completo" />
                    )}
                    </Form.Item>
                    <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Por favor crie uma senha' }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large" type="password" placeholder="Senha" />
                    )}
                    </Form.Item>
                    <Button 
                        type="default" 
                        size="large" 
                        htmlType="submit" 
                        className="login-form-button" 
                        loading={loading}
                        block>
                        Completar cadastro
                    </Button>
                </Form>
            )}
            {status == 'error' && (
            <div>
                <h1 style={{color: `#fadb14`}}><Icon type="warning"/> {error}</h1>
                <a href={'/'}>Ir para a página inicial</a>
            </div>
            )}
            </Spin>
        );
    }
}
export default withRouter(Form.create()(InvitedForm));