import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';
import Auth from './Auth';

import logo from './images/icon.png';
import logo2 from './images/logo.png';

import './layout.css';

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Main extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { user } = this.props;
    const { collapsed } = this.state;

    return (
      <Auth>
        {user.permissions && (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            trigger={null}
            width={250}
            style={{ 
              overflow: 'auto', 
              height: '100vh', 
              position: 'fixed', 
              left: 0,
              zIndex: '990'
            }}
          >
              <div style={{backgroundColor: 'rgb(37, 55, 160)', textAlign: `center`, padding: `10px`}}>
                  {collapsed? <img src={logo} className="logo" alt="Solução CRM" height="20"/>:
                  <img src={logo2} className="logo" alt="Solução CRM" style={{height: '40px'}}/>}
              </div>
              <Menu 
                theme="dark" 
                defaultSelectedKeys={[this.props.location.pathname]} 
                mode="inline"
                onClick={({key}) => this.props.history.push(key)}
                >
                {/* {(user.permissions.includes('1')) && ( */}
                <Menu.Item key="/">
                  <Link to="/"><Icon type="dashboard" /> <span>Dashboard</span></Link>
                </Menu.Item>
                {/* )} */}
                {/* {(user.permissions.includes('2')) && ( */}
                <Menu.Item key="/propostas">
                  <Link to="/propostas"><Icon type="profile" /> <span>Propostas</span></Link>
                </Menu.Item>
                {/* )} */}
              </Menu>
          </Sider>
          <Layout style={{marginLeft: `${collapsed? '80px': '250px'}`}}>
            <Header id="header-main" style={{
              background: '#fff', 
              padding: 0,
              boxShadow: `0px 1px 8px 0px rgba(0,0,0,0.1)`,
              zIndex: `989`
              }}>
              <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
                <Dropdown  className="cl-menu-main-right" overlay={
                    <Menu>
                      {/* <Menu.Item key="/conta">
                        <Link to="/conta"><Icon type="user" /> Minha Conta</Link>
                      </Menu.Item> */}
                      {(user.perfil === 'Administrador') && (
                      <Menu.Item key="/usuarios">
                        <Link to="/usuarios"><Icon type="team" /> Gerenciar Usuários</Link>
                      </Menu.Item>
                      )}
                      {/* <Menu.Divider/> */}
                      <Menu.Item key="/sair">
                        <Link to="/sair"><Icon type="logout" /> Sair</Link>
                      </Menu.Item>
                    </Menu>
                  }>
                  <a className="ant-dropdown-link" href="#">
                  {user.name} <Icon type="down" />
                  </a>
              </Dropdown>
            </Header>
            <Content style={{ margin: `0px 16px` }}>
              {this.props.children}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Solução CRM ©2019
            </Footer>
          </Layout>
        </Layout>
        )}
      </Auth>
    );
  }
}

const mapDispatchToProps = (state) => ({
  user: state.user
});

export default connect(mapDispatchToProps)(withRouter(Main));